import qs from 'query-string';
import { QueryParams } from '@/hooks/useApi';

/**
 * Format an object of query params into a string that can be appended to a URL
 * @param {QueryParams} query
 * @return {string}
 */
export const makeQueryString = (query: QueryParams) => {
    return qs.stringify(query);
};
/**
 * For a given path or url, append query params if they exist. This will not modify the original path/url in any way.
 * @param {string} input - the path or url string to append query params to
 * @param {QueryParams | null} params
 * @return {string}
 */
export const appendQueryParams = (input: string, params?: QueryParams | null) => {
    if (!params) {
        return input;
    }
    const queryString = makeQueryString(params);
    if (queryString) {
        return `${input}?${queryString}`;
    }
    return input;
};
export const ensureLeadingSlash = (_path: string) => {
    const path = _path.replace(/^\/+/g, '');
    return `/${path}`;
};
export const ensureTrailingSlash = (_path: string) => {
    const path = _path.replace(/^\/+/g, '');
    return `${path}/`;
};
export const ensureSlashes = (_path: string) => {
    const path = _path.replace(/^\/+|\/+$/g, '');
    return `/${path}/`;
};
export const removeTrailingSlash = (input: string) => {
    return input.replace(/\/+$/, '');
};
export const removeLeadingSlash = (input: string) => {
    return input.replace(/^\/+/, '');
};

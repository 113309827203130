import { ensureLeadingSlash } from '@/util/url-util';

export const AUTH0 = {
    CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? '',
    DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? '',
    AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ?? '',
    CLAIMS_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_CLAIMS_DOMAIN ?? 'https://prefaremeals.com',
    TENANT_ID: process.env.NEXT_PUBLIC_AUTH0_TENANT_ID ?? 'prefare-stage',
};

export const API = {
    API_HOST: process.env.NEXT_PUBLIC_API_HOST ?? '',
    BASE_URL: `/api/v${process.env.NEXT_PUBLIC_API_VERSION}` ?? '',
    SECURE_URL_PART: '/secured/',
    PUBLIC_URL_PART: '/',
    makeUrl: (path: string) => `${API.API_HOST}${API.BASE_URL}${ensureLeadingSlash(path)}`,
};

export const STRIPE = {
    API_KEY: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
};

export const COMMIT_HASH = process.env.VERCEL_GIT_COMMIT_SHA ?? process.env.COMMIT_HASH ?? undefined;

export const GOOGLE_ANALYTICS = {
    MEASUREMENT_ID: process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID ?? '',
    DEBUG_MODE: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_DEBUG?.toLowerCase() === 'true',
    TRAFFIC_TYPE: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_DEBUG?.toLowerCase() === 'true' ? 'internal' : '',
};

export const SENTRY = {
    DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    ENV: process.env.NEXT_PUBLIC_SENTRY_ENV ?? 'development',
    TRACES_SAMPLE_RATE: Number.parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE ?? '0.05'),
    NORMALIZE_DEPTH: Number.parseInt(process.env.NEXT_PUBLIC_SENTRY_NORMALIZE_DEPTH || '15'),
    REPLAY_SAMPLE_RATE: Number.parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAY_SAMPLE_RATE || '0.1'),
    REPLAY_ERROR_SAMPLE_RATE: Number.parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAY_SAMPLE_RATE || '1.0'),
    SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED?.toLowerCase() !== 'false',
};

export const UPLOADCARE = {
    PUBLIC_KEY: process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY,
};

export const ZENDESK = {
    API_KEY: process.env.NEXT_PUBLIC_ZENDESK_KEY ?? '',
};

export default {
    AUTH0,
    API,
    STRIPE,
    GOOGLE_ANALYTICS,
    UPLOADCARE,
    ZENDESK,
    VERCEL: {
        ANALYTICS_ID: process.env.VERCEL_ANALYTICS_ID, //REACT_APP_VERCEL_ANALYTICS_ID
    },
    IS_DEV: process.env.NODE_ENV !== 'production',
    DISABLE_UNLOAD_HANDLERS: process.env.NEXT_PUBLIC_DISABLE_MENU_UNLOAD === 'true',
};
